<template>
  <section class="slogan-section">
    <div class="container">
      <h1 class="slogan-section__title">Na webe usilovne pracujeme</h1>
    </div>
  </section>
</template>

<script>
import TypeIt from "typeit";

export default {
  name: "SloganAnimation",
  mounted() {
    new TypeIt(".slogan-section__title", {
      speed: 100,
      loop: true,
    })
      .pause(1000)
      .delete()
      .type("Vytvárame")
      .pause(500)
      .break()
      .type("UX/UI dizajn")
      .pause(1000)
      .delete(12)
      .type("Webové aplikácie")
      .pause(1000)
      .delete(16)
      .type("Mobilné aplikácie")
      .pause(1000)
      .delete(17)
      .type("ERP/CRM systémy")
      .pause(1000)
      .delete(15)
      .type("E-shopy a B2B systémy")
      .pause(1000)
      .delete(21)
      .type("Cloudové riešenia")
      .pause(1000)
      .delete(17)
      .type("Chatbotov na mieru")
      .pause(1000)
      .go();
  },
};
</script>
