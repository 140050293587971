<template>
  <div class="flip-card-section mt-lg-5 mb-5">
    <div class="flip-card d-none d-sm-flex">
      <div class="flip-card-inner">
        <div class="flip-card-front">
          <img :src="require('../assets/back.svg')" alt="Vizitka - watee" />
        </div>
        <div class="flip-card-back">
          <img :src="require('../assets/front.svg')" alt="Vizitka - watee" />
        </div>
      </div>
      <div class="tap">
        <img :src="require('../assets/tap.svg')" width="50" />
        <span>Zobraziť vizitku</span>
      </div>
    </div>
    <div class="flip-card-horizontal d-flex d-sm-none">
      <div class="flip-card-inner">
        <div class="flip-card-front">
          <img
            :src="require('../assets/horizontal-front.svg')"
            alt="Vizitka - watee"
          />
        </div>
        <div class="flip-card-back">
          <img
            :src="require('../assets/horizontal-back.svg')"
            alt="Vizitka - watee"
          />
        </div>
      </div>
      <div class="tap">
        <img :src="require('../assets/tap.svg')" width="50" />
        <span>Zobraziť vizitku</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavComponent",
};
</script>
