<template>
  <div class="relative bg-silver-md">
    <!-- container -->
    <main class="relative max-w-5xl mx-auto">
      <nav-component />
      <slogan-animation />
      <business-card />
    </main>
    <footer>
      <div class="container">
        <div class="footer">
          © watee s. r. o., 2022. Všetky práva vyhradené.
        </div>
      </div>
    </footer>
    <!-- end container -->
  </div>
</template>

<script>
import NavComponent from "./components/NavComponent.vue";
import SloganAnimation from "./components/SloganAnimation.vue";
import BusinessCard from "./components/BusinessCard.vue";

export default {
  name: "App",
  components: {
    NavComponent,
    SloganAnimation,
    BusinessCard,
  },
};
</script>
Navigation
