<template>
  <nav class="navigation">
    <a href="https://watee.sk">
      <img
        :src="require('./../assets/logo.svg')"
        class="navigation__logo"
        alt="watee.digital"
    /></a>
  </nav>
</template>

<script>
export default {
  name: "NavComponent",
};
</script>
